import { Component } from "@angular/core";

import RFB from "../../node_modules/@novnc/novnc/core/rfb.js";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "vnc-client";

  public rfb: RFB;

  startClient() {
    console.log("Starting !!!");

    // Read parameters specified in the URL query string
    // By default, use the host and port of server that served this file
    const host = 'wstexer.f13comunicazioni.it'; //'localhost';  //'192.168.54.168'; //'185.5.244.204'; 
    const port = "";
    const password = "hfRR$dS2"; //"pippo"; // password of your vnc server
    const path = "?ip=185.5.244.204:5900";
    // Build the websocket URL used to connect
    let url = "http";

    if (window.location.protocol === "https:") {
      url = "wss";
    } else {
      url = "ws";
    }

    url += "://" + host;
    if (port) {
      url += ":" + port;
    }
    url += "/" + path;

    console.log("URL: ", url);

    // Creating a new RFB object will start a new connection
    this.rfb = new RFB(document.getElementById("screen"), url, {
      
      credentials: { password: password }
    });

   
    this.rfb.scaleViewport = false;
    this.rfb.clipViewpost = true;
  }
}
