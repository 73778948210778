<!--The content below is only a placeholder and can be replaced.-->
<div style="text-align: center;">
  <h1>Welcome to {{ title }}!</h1>
  <button type="submit" (click)="startClient()">Start Vnc Client</button>
</div>
<div style="display: absolute;">
  <div id="screen" style="width: 50%; height: 50%; overflow: scroll;">
    <!-- This is where the remote screen will appear -->
  </div>
</div>
